import { useHistory } from "react-router";
import { TopLine } from '../../components/top-line/TopLine';
import { useAppDispatch, useAppSelector } from "../../hooks";
import { selectPageCategories, CategoryLoadStatus, onNextPage, selectCategoriesLoadStatus, onChangeHowItWorksWrapperShow, selectHowItWorksWrapperShow, selectSimProductId, selectCategory, selectIsNeedNextPage, selectDefaultQr, selectDefaultQrIndex, selectSelectPromoData, selectDefaultQrUsedIndex } from "./homeSlice";

import './home.scss';
import { useEffect } from "react";
import { selectLoadingStatusState, selectOrderStatusState } from "../order-status/orderStatusSlice";
import { Loading } from "../../components/loading/Loading";
import { HowItWorks } from "../how-it-works/HowItWorks";
import { onCloseAll } from "../how-it-works/howItWorksSlice";
import userSmartHistory from "../../metrica/metrica";
import { WaitReceipt } from "../../components/wait-receipt/WaitReceipt";
import { Category } from "../../sdk/client/model";
import { isSaled } from "../product-page/productPageSlice";
import { qrAction } from "../../store/qrmid";
import { openPromoWindow, openSaleWindow } from "../saled-window/saledWindowSlice";



export function Home() {
    const homeLoadingStatus = useAppSelector(selectLoadingStatusState);
    const homeStatus = useAppSelector(selectOrderStatusState)

    const howItWorksWrapperShow = useAppSelector(selectHowItWorksWrapperShow);
    let navigate = userSmartHistory();
    const dispatch = useAppDispatch();

    let categories = useAppSelector(selectPageCategories);
   
    let onClickCategoryHandler = (category: Category) => {
        dispatch(selectCategory(category))
        if (!category.disabled) {
            navigate.push(`/category/${category.id}/products`);
        }
    }
    const categoriesLoadStatus = useAppSelector(selectCategoriesLoadStatus)
    const simProductId = useAppSelector(selectSimProductId)
    const isNeedNextPage = useAppSelector(selectIsNeedNextPage)
    let isSaledValue = useAppSelector(isSaled);
    let promoData = useAppSelector(selectSelectPromoData);
    let defaultQrIndex = useAppSelector(selectDefaultQrIndex);
    let usedQrIndex = useAppSelector(selectDefaultQrUsedIndex);
    useEffect(() => {
        console.log(`Promo screen: ${promoData?.default_qr} - ${defaultQrIndex}`)
        if (usedQrIndex!=defaultQrIndex){
            setTimeout(() => {
                if (promoData != null) {
                    let qrActionItem = openPromoWindow({type:'promo',index:defaultQrIndex})
                    dispatch(qrActionItem)
                }
            }, 500)
        }else{
            console.log(`Skip by windows ${usedQrIndex}`)
        }
    }, [promoData?.default_qr,defaultQrIndex])

    if (categoriesLoadStatus == CategoryLoadStatus.Loading) {
        return <Loading />
    }

    return (<>

        {howItWorksWrapperShow ? (
            <HowItWorks onChangeHowItWorksWrapperShow={() => { dispatch(onChangeHowItWorksWrapperShow()) }}></HowItWorks>
        ) : null}


{/* 
        <TopLine onClick={() => {
            dispatch(onCloseAll())
            dispatch(onChangeHowItWorksWrapperShow())

        }}
            sectionStyle='left'
            sectionText='?'
            sectionHeading=''
            sectionType='text'>
            <div> Как <span className="primaryText">это работает</span></div>
        </TopLine>
 */}

        <section className="homepage-buttons">
            <div className="container">
                <button onClick={() => {

                    navigate.push('/repair-options')

                }} className="homepage-button first">
                    <span className="homepage-button_text">Отправить <br />в ремонт</span>
                </button>
                <button onClick={() => {
                    navigate.push('/qr-scan')
                }} className="homepage-button second">
                    <span className="homepage-button_text">Забрать <br />из ремонта</span>
                </button>
            </div>
        </section>


        <TopLine sectionStyle='left'
            sectionHeading=''
            sectionType='icon'
            simProductId={simProductId}
            sectionIconName='shopping-cart.svg'>
            <span>Товары&nbsp;</span><div className="primaryText">в наличии</div>
        </TopLine>

        <section className="item-list">
            <div className="container">
                {categories.map((c: Category) =>
                    <div key={c.id} className={`item-card category-card ${c.disabled == true ? ' disabled' : ''}`} onClick={() => { onClickCategoryHandler(c) }}>
                        <span className="item-card_title">{c.name}</span>
                        <img src={c.img} alt="" className="item-card_pic" />
                        <div className={`item-card-price-block ${isSaledValue ? 'sale' : ''}`}>
                            <div className="item-card_price_sale">от {c.min_discount_price}₽</div>
                            <img className='crossSale' src="/assets/img/pricecross.svg" />
                            <div className="item-card_price">от {c.min_price}₽</div>
                        </div>
                    </div>
                )}
                {
                    isNeedNextPage ?
                        (<div className={`item-card category-card next`} onClick={() => { dispatch(onNextPage()) }}>
                            <img src="assets/img/nav_next.png" alt="" />
                        </div>) : (<></>)
                }

            </div>
        </section>

        {/* <button onClick={()=>{navigate.push('/service')}} className="footer_service-link">
            Сервис
        </button> */}
    </>)

}
