import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../store/store"

export interface StoriesItem{
    name: string
    image: string
    videos?: string[]
}

export interface StoriesState {
  
 
    stories:StoriesItem[]
}

const initialState: StoriesState = {
    // 1) Работа терминала (расположить в 2 строчки)

// 2) О НАС

// 3) Мастерская

// 4) Отзывы

// 5) Локации

    stories: [
        {
            name: "Работа терминала",
            image: '/assets/img/stories/work.jpg'
        },
        {
            name: "О нас",
            image: '/assets/img/stories/about.jpg'
        },
        {
            name: "Мастерская",
            image: '/assets/img/stories/workshop.jpg'
        },
        {
            name: "Отзывы",
            image: '/assets/img/stories/reviews.jpg'
        },
        {
            name: "Локации",
            image: '/assets/img/stories/location.jpg'
        },

    ]
}

export const storiesState = createSlice({
  name: 'StoriesState',
  initialState,
  reducers: {
   
    
   
  },
  extraReducers: (builder) => {
    builder.addCase("CLEANUP", (state) => {

    });

  }
})

export const {  } = storiesState.actions



export default storiesState.reducer
